.active {
  color: var(--contrast);
}

.left {
  border-radius: 5px 0 0 5px !important;
}

.middle {
  border-radius: 0 !important;
  border-left: none;
}

.right {
  border-radius: 0 5px 5px 0 !important;
  border-left: none;
}

.navButton {
  padding: 8px 24px !important;
}
