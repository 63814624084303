:root {
  /* Legacy woolit colours */
  --brown-dark: var(--legacy-woolit); /* Use --dark-brown instead */
  --brown-medium: var(--legacy-woolit); /* Use --light-brown instead */
  --brown-light: var(--legacy-woolit); /* Use --whiteish instead */
  --border-color: var(--legacy-woolit); /* Use --neutral-10 instead */
  --legacy-woolit: #350070; /* Don't */
  --brown-medium-opacity: var(--legacy-woolit); /* Use --neutral-10 instead */

  /* Font families */
  --header-font-family: "forma-djr-display", sans-serif;
  --small-font-family: "forma-djr-micro", sans-serif;
  --text-font-family: "forma-djr-text", sans-serif;

  /* New colours */
  --darkest-brown: #33251a;
  --dark-brown: #563e2c;
  --light-brown: #8f6d57;
  --whiteish: #faf6f2;
  --positive: #3d8036;
  --contrast: #cc4e00;
  --negative: #e0310d;
  --lightGreen: #D5CE8A;
  --mainBrown: #69513E;

  /* Neutral tones */
  --neutral-50: #aa9e95;
  --neutral-40: #bbb2ab;
  --neutral-30: #ccc5c0;
  --neutral-20: #ddd8d5;
  --neutral-10: #eeecea;

  /* Text colours */
  --text-color: var(--dark-brown);
  --header-color: var(--darkest-brown);
}

body {
  margin: 0;
  font-family: var(--text-font-family), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2 {
  color: var(--header-color);
}

h2 {
  font-family: var(--text-font-family);
  font-size: 38px;
  font-weight: 400;
  line-height: 44px;
}

h1 {
  font-family: var(--header-font-family);
  font-size: 2.1em;
  font-weight: 200;
}

p,
em,
div {
  font-family: var(--text-font-family);
  color: var(--text-color);
  margin: 0;
  line-height: 1.3em;
  letter-spacing: 0.03em;
}

input,
select {
  border: 2px solid var(--neutral-10);
  border-radius: 2px;
  color: var(--text-color);
  font-size: 18px;
  font-family: var(--text-font-family);
}

button {
  padding: 5px 10px 3px 10px;
  border: 2px solid var(--neutral-10);
  font-family: var(--small-font-family);
  border-radius: 4px;
  background-color: #fff;
  color: var(--dark-brown);
  font-size: inherit;
  cursor: pointer;
}

button:hover {
  background-color: var(--neutral-10);
}
