:root {
  --darkest-brown: #33251a;
}

.roles {
  display: flex;
  flex-flow: column nowrap;

  margin-top: 16px;
}

.role {
  display: flex;
  flex-flow: row nowrap;
}

.role input {
  flex-grow: 1;

  border: 2px solid var(--neutral-10);
  border-radius: 4px;
  padding: 4px 8px;

  font-size: 12px;
  line-height: 22px;
  color: var(--darkest-brown);
  font-family: var(--text-font-family);
}

.roleTitle {
  font-weight: 700;
}

.roleValue {
  margin-left: 16px;
}

.role + .role {
  margin-top: 8px;
}

.spacer {
  margin: 16px 0;
  padding: 0;
}

.new {
  color: var(--contrast);
  font-family: var(--text-font-family);
  font-size: 14px;
}

.removeItem {
  margin-left: 16px;
}

.removeItem:disabled {
  opacity: 0;
  cursor: default;
}
