.overviewContainer {
  max-width: 2000px;
  margin: auto;
  padding: 30px;
  background: white;
}

.patternTitle {
  display: flex;
  color: black;
}
.patternSum {
  padding: 10px;
  color: black;
}

.monthOverviewTable {
  background-color: var(--whiteish);
}

.monthTableHeader {
  font-weight: bolder;
  display: flex;
  border-bottom: 1px solid #cfcfcf;
  padding-top: 10px;
  padding-bottom: 10px;
}

.monthOverview {
  padding: 10px;
  max-width: fit-content;
  background-color: var(--whiteish);
}

.tableItem {
  width: 300px;
  font-size: 16px;
  text-align: start;
  box-sizing: border-box;
}

.tableItemSales {
  width: 300px;
  font-size: 16px;
  text-align: start;
  box-sizing: border-box;
  margin-left: 400px;
}

.tableItemSum {
  width: 300px;
  font-size: 16px;
  text-align: start;
  box-sizing: border-box;
  margin-left: 100px;
}

.tableItemDetails {
  width: 300px;
  font-size: 16px;
  text-align: start;
  box-sizing: border-box;
  margin-left: 50px;
  text-align: end;
}

.tableHeader {
  font-weight: bolder;
  display: flex;
  color: black;
}

.patternRow {
  display: flex;
  background-color: var(--whiteish);
  border-bottom: 1px solid #cfcfcf;
  padding-top: 10px;
  padding-bottom: 10px;
}

.headerItem {
  background-color: #f0e9e4;
  width: 400px;
  padding: 5px;
  display: flex;
  justify-content: end;
  
}

.headerItem .date{
  width: 700px;
  font-size: 16px;
  text-align: start;
  box-sizing: border-box;
}

.headerItem .pattern{
  width: 300px;
  font-size: 16px;
  text-align: start;
  box-sizing: border-box;
  margin-left: 400px;
}
.headerItem .total{
  
  color: var(--positive);
  border: none;
  width: 300px;
  align-self: flex-end;
  margin-left: 50px;
  text-align: end;


}

.headerItem .yarn{

  width: 300px;
  font-size: 16px;
  text-align: end;
  box-sizing: border-box;
  margin-left: 100px;
   
  
}


.tableRow {
  display: flex;
  background-color: #f0e9e4;

  border-color: black;
}

.columnItem {
 width: 400px;
  padding: 5px;
  border-top: 0.8px solid #cfcfcf;
  display: flex;
  justify-content: end;
  
}

.columnItem .date{
  width: 700px;
  font-size: 16px;
  text-align: start;
  box-sizing: border-box;
}

.columnItem .pattern{
  width: 300px;
  font-size: 16px;
  text-align: start;
  box-sizing: border-box;
  margin-left: 400px;
}

.columnItem .total{
  color: var(--positive);
  border: none;
 
  width: 300px;
  align-self: flex-end;
  margin-left: 50px;
  text-align: end;
}

.columnItem .yarn{
  width: 300px;
  font-size: 16px;
  text-align: end;
  box-sizing: border-box;
  margin-left: 100px;
  
}

.salesTable {
  background-color: #f0e9e4;
  
  flex-direction: column;
  
  
}

.mainOverview {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.overviewCard {
  background-color: var(--whiteish);
  display: flex;
  height: 100px;
  margin-top: 10px;
  min-width: 300px;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.overviewStatisticValue {
  font-size: 24px;
}

.periodChangeValue {
  font-size: 24px;
  color: var(--positive);
}

.monthTitle {
  font-size: 22px;
  padding: 10px;
}

.expandButton {
  color: var(--positive);
  border: none;
  background-color: var(--whiteish);
  width: 300px;
  align-self: flex-end;
  margin-left: 50px;
  text-align: end;
}

.totalMonthSum {
  font-size: 19px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 300px;
}

@media (max-width: 1099px) {
  .tableItem {
    margin-left: 20px;
  }

  .tableItemSales {
    margin-left: 30px;
    text-align: center;
  }

  .tableItemSum {
    margin-left: 30px;
  }

  .tableItemDetails {
    margin-left: 20px;
    text-align: center;
  }

  .expandButton {
    font-size: 15px;
    text-align: center;
    margin-left: 20px;
  }

  .totalMonthSum {
    font-size: 15px;
    font-weight: bold;
  }

  .overviewStatisticValue, .periodChangeValue {
    font-size: 17px;
    font-weight: bold;
  }


  .columnItem, .headerItem {
    width: 100px;
    padding-left: 20px;
  }

  
}




@media (max-width: 400px) {
  .patternRow,
  .monthTableHeader,
  .monthOverview {
    width: 380px;
  }
  .overviewCard {
    height: 60px;
  }
  .overviewContainer {
    padding: 0px;
    box-sizing: border-box;
  }
}

