.metadata {
  margin: 0 auto;
}

.background {
  margin: 0;
  padding: 0;

  background-image: linear-gradient(#faf6f2 1px, transparent 0.1em),
    linear-gradient(90deg, #faf6f2 1px, transparent 0.1em);
  z-index: -2;
}

.coverScreen {
  width: 100vw;
  height: 100vh;
}

.coverCanvas {
  min-width: 200vw;
  min-height: 300vh;
}

.editorContainer {
  display: flex;
  justify-content: space-between;
}

.menuLeft {
  position: absolute;
  top: 42.5%;
  transform: translate(0, -50%);
  z-index: 1;
  margin-left: 24px;
}

.noHoverColor:hover {
  background-color: #fff;
}

.hasHoverContent > .hoverContent{
  display: none;
}

.hasHoverContent:hover > .hoverContent{
  display: block;
}

.bottom {
  position: -webkit-fixed;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 200;
  pointer-events: none;
  /* height: 96px; */
}

.addButton {
  border: 1px solid var(--contrast);
  border-radius: 50px;
  color: var(--contrast);
  font-size: 10px;
  padding: 0 4px;
  z-index: 10;
}

.toolContainer {
  border: 2px solid var(--neutral-10);
  padding: 2px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  margin-bottom: 12px;
}

.centralNewDiagramButtonContainer {
  display: flex;
  width: 100vw;
  justify-content: center;
  margin-top: 50%;
  transform: translate(0, -50%);
}
.centralNewDiagramButton {
  padding: 48px 64px;
  font-size: 24px;
  border: 1px solid var(--neutral-40);
}

.toolButton {
  border: none;
  flex: 1;
}

.borderBottom {
  border-bottom: 1px solid var(--neutral-10);
}

.positive {
  color: var(--positive);
}

.contrast {
  color: var(--contrast);
}

.hoverRow:hover {
  background: #faf6f2;
}

.border {
  border-radius: 3px;
  border: 2px solid var(--neutral-10);
}

.textHover:hover {
  color: #de5900;
}

.nestedMenu {
  background: white;
  border-radius: 4px;
  border: 2px solid var(--neutral-10);
  width: 120px;
}

.stateButton:disabled {
  background-color: var(--neutral-10);
}

.modalContainer {
  padding: 40px 80px;
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.modalContent > input {
  margin-bottom: 16px;
}

input {
  width: 50px;
  background-color: var(--neutral-10);
  border: none;
  border-radius: 5px;
  padding: 2px 5px 2px 5px; 
}

.modalContent > label {
  font-size: 12px;
  font-weight: bold;
}

.stateButtons {
  display: flex;
  margin-bottom: 8px;
}

.stateButtons > * + * {
  margin-left: 8px;
}

.input {
  display: flex;
  margin: 2px 4px;
  justify-content: space-around;
  align-items: center;
}

.input > label {
  width: 62px;
}

.diagramList {
  
  gap: 20px;

  display: flex;
  /* height: 96px; */
  align-items: flex-end;
  height: 120px;
}

.overflowTool {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropDownMenu {
  background: rgb(255, 255, 255, 0.4);
  padding: 2px 2px 0px 2px;
}
