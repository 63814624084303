.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 50px 0;
}

.label {
  text-transform: uppercase;
  color: var(--header-color);
  font-size: 12px;
  font-weight: 500;
}

.textInput {
  padding: 10px;
  border: 2px solid var(--neutral-10);
  border-radius: 2px;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 2px;
  color: var(--text-color);
}

.loginInfo {
  color: var(--text-color);
}

.modal {
  overflow: scroll;

  z-index: 10;
  background-color: #ffffff;
  width: 100%;
  height: 100%;

  max-height: 100vh;
  border-width: 1px;
  margin-left: 30%;
  margin-right: 30%;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);

  @media (max-width: 400px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.innerModal {
  margin-left: 7rem;
  margin-right: 7rem;
  margin-top: 3rem;
  margin-bottom: 5rem;

  align-self: center;
  align-content: center;

  @media (max-width: 400px) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
